import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginInterface } from '../../interfaces/login.interface';
import { LoginService } from '../../services/login.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  logoHMY = 'assets/images/logoYudigar.png';
  email: string = '';
  password: string = '';
  loading: boolean = false;

  constructor(private loginService: LoginService,
    private router: Router) {
      
     }

  ngOnInit(): void {
    window.localStorage.clear();
  }

  login() {
    if (this.email == '' || this.password == '') {
      alert('Todos los campos son requeridos')
      return;
    }

    const timezone = moment.tz.guess();
    localStorage.setItem('timezone', timezone);
    const loginData: LoginInterface = {
      email: this.email,
      password: this.password,
    }

    this.loading = true;
    this.loginService.auth(loginData).subscribe((res: any) => {
      localStorage.setItem('token', res.accessToken);
      localStorage.setItem('iduser', res.id);
      localStorage.setItem('username', res.name);
      localStorage.setItem('email', res.email);
      localStorage.setItem('permissions', res.permissions);
      this.loginService.updateLoginStatus(true);
      this.loginService.updateShowResumen(true);
      this.router.navigate(['start']);
    }, (err) => {
      this.loginService.updateLoginStatus(false);
      this.loginService.updateShowResumen(false);
      this.loading = false;
      if (err.status == 404) {
        alert('Usuario o password invalidos');
      }
      console.log('error', err);
    })
  }

}
