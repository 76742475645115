import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginInterface } from '../interfaces/login.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loginStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private showResumen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pingResult: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private diagnosticResult: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private role: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  public loginStatus$: Observable<boolean> = this.loginStatus.asObservable();
  public showResumen$: Observable<boolean> = this.showResumen.asObservable();
  public isAdmin$: Observable<boolean> = this.isAdmin.asObservable();
  public pingResult$: Observable<string> = this.pingResult.asObservable();
  public diagnosticResult$: Observable<string> = this.diagnosticResult.asObservable();
  public role$: Observable<number> = this.role.asObservable();

  constructor(private http: HttpClient) {}

  auth(loginData: LoginInterface) {
    return this.http.post(`${environment.apiUrl}/auth/log-in`, loginData);
  }

  updateLoginStatus(status:boolean) {
    this.loginStatus.next(status);
  }

  updateShowResumen(show:boolean) {
    this.showResumen.next(show);
  }

}
