import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JiraService } from 'src/app/services/jira.service';
import { PlatformService } from '../../services/platform.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  loading: boolean = false;
  
  clients: any[] = [{id: -1, name: "Seleccionar"}];
  clientSelected = -1;
  configuration = -1;

  constructor(private router: Router,
    private _jiraService: JiraService) { }

  ngOnInit(): void {
    this._jiraService.getClients(1, 100).subscribe((res: any) => {
      this.clients = this.clients.concat(res);
    }, (err) => {
      console.log('Err', err);
    })
  }

  changeConfig() {
    const client = this.getClientById(this.clientSelected);
    console.log({client, clientSelected: this.clientSelected})
    if (this.configuration.toString() == 'KIOSKOS/ENCAPSULADOR') {
        alert('No disponible por el momento');
    } else if (this.configuration.toString() == 'CLICK &HMY') {
        localStorage.setItem('clientId', this.clientSelected.toString());
        localStorage.setItem('clientName', client.name);
        localStorage.setItem('configuration', this.configuration.toString());
        this.router.navigate(['home']);
    }
  }

  getClientById(id) {
    for (const client of this.clients) {
        if (client.id == id) {
            return client;
        }
    }
    return false;
  }

  validateFields(model: any) {
    if (this.clientSelected == -1) {
      alert('Debe seleccionar un cliente');
      return false;
    }
    if (model.lockerId == '') {
      alert('Debe completar el campo lockerId');
      return false;
    }
    return true;
  }

}
