import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
  }

  getClients() {
    return this.http.get(`${environment.apiUrl}/platform/clients`, { headers: { Authorization: this.token } })
  }

  createJSONs(data: any) {
    return this.http.post(`${environment.apiUrl}/platform/json`, data, { headers: { Authorization: this.token } })
  }

  getJSON(lockerId, config) {
    return this.http.get(`${environment.apiUrl}/platform/json/${lockerId}/${config}`, { headers: { Authorization: this.token } })
  }

}
