<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<style>
  
</style>

<!-- Toolbar -->
<div class="navbar toolbar" role="banner" *ngIf="logged">
  <div style="display: flex; align-items: center;">
    <img
      width="90"
      alt="Angular Logo"
      [src]="logoHMY"
    />
    <span>Configurador de Nuevos Equipos</span>
    <a class="nav-link active" style="color: #888 !important; cursor: pointer;" *ngIf="logged && role == 3" aria-current="page" (click)="goToUsers()">Usuarios</a>
  </div>
  <a style="color: white; margin-right: 10px; cursor: pointer;" (click)="logout()" *ngIf="logged">Logout</a>
</div>
<div [ngClass]="{'container mt-4': logged}">
  <div class="row justify-content-md-center" *ngIf="logged && route != '/start' && showResumen">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <ul style="margin-bottom: 0;">
                <li>Cliente: {{clientName}}</li>
              </ul>
            </div>
            <div class="col-md-6">
              <ul>
                <li>Tipo de Solución: {{configuration}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>