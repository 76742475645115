import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JiraService {

  token: string;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
  }

  getAllInactiveLockers(page, resultsPerPage) {
    return this.http.get(`${environment.apiUrl}/jira/lockers/inactive/${page}/${resultsPerPage}`, { headers: { Authorization: this.token } })
  }

  getClients(page, resultsPerPage) {
    return this.http.get(`${environment.apiUrl}/jira/clients/${page}/${resultsPerPage}`, { headers: { Authorization: this.token } })
  }

}
