import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NextClass } from '../../common/classes/next.class';

@Component({
  selector: 'message-step',
  templateUrl: './message-step.component.html',
  styleUrls: ['./message-step.component.scss']
})
export class MessageStepComponent implements OnInit {

  message: string;

  public constructor() {}
  
  ngOnInit() {
    
  }

  @Output() next = new EventEmitter<NextClass>();

  nextAction() {
    const dataAction: NextClass = {
      step: 1,
      data: {
        message: this.message
      }
    }
    this.next.emit(dataAction);
  }
  
}
