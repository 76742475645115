<div class="container mt-4">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label for="clientSelected" class="form-label">Seleccione Cliente</label>
            <select name="clientSelected" [(ngModel)]="clientSelected" id="clientSelected" class="form-control">
              <option *ngFor="let client of clients" [value]="client.id" >{{client.name}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="configuration" class="form-label">Seleccione tipo de Solución</label>
            <select name="configuration" [(ngModel)]="configuration" (change)="changeConfig()" id="configuration" class="form-control">
              <option value="-1">Seleccionar</option>
              <option value="KIOSKOS/ENCAPSULADOR">KIOSKOS/ENCAPSULADOR</option>
              <option value="CLICK &HMY">CLICK &HMY</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center mt-5 text-start">
      <div class="col-md-11">
        <p> Bienvenido a la herramienta para generar los ficheros de configuración de las distintas soluciones Tech de HMY-Group. </p>
        <p>Por favor, siga las instrucciones que se indican por pantalla.</p>
        <p>En caso de dudas, por favor contacte con el departamento DEV de Retail Tech de HMY-Group.</p>
      </div>
    </div>
</div>