import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateTransform',
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    transform(value: any, ): any {
    const dateAux = new Date(value);
    return super.transform(dateAux, 'dd / MM / yy - hh:mm');
    }
}
