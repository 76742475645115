import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '../../services/platform.service';
import { SpecModel } from './model/spec.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginService } from '../../services/login.service';
import { JiraService } from '../../services/jira.service';
import * as uuid from 'uuid';
import html2canvas from 'html2canvas';
import { SpecialParamsModel } from './model/specialParams.model';
import { isIdentifier } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('addUser', { read: TemplateRef }) addUserTemplate:TemplateRef<any>;

  loading: boolean = false;
  lockerId: string = 'Seleccionar';
  lockerIds = [
    {id: -1, lockerId: 'Seleccionar'}
  ];
  lockerIdsClients = [];
  languageSelected = {};

  string = String;
  specModel: SpecModel = {
    lockerLocation: '',
    timeOutSS: '',
    locksPerBoard: '',
    boardsNumber: '',
    descriptionLocker: '',
    screenSaverShow: '',
    screenSaverElement: '',
    showScreenSaverQR: '',
    showScreenSaverLogo: '',
    showCustomerSection: '',
    supportContactPhone: '',
    webhookEnable: '',
    webhookURL: '',
    languages: [],
    languageSelected: '',
    bussinessTypes: [],
  };

  paramsModel: any = {
    lateOpenDelay: 5,
    expiredDelayCC: 168,
    expiredDelayCustomer: 24,
    waitForCCNotification: 5,
    notificationTimeOutPreExpiredOrderCCMinutes: '',
    notificationTimeOutPreExpiredOrderConsignaMinutes: '',
    expiredDelayStaff: 0,
    statusLocker: '',
    outOfService: '',
    onlyPickupMode: '',
    hasPayment: '',
    consignaCostPerMinute: 0,
    lyraEmail: '',
    lyraPublicKey: '',
    lyraClientToken: '',
    companyName: '',
    supportCompanyName: '',
    supportCompanyNameES: '',
    supportCompanyNameEN: '',
    supportCompanyNameFR: '',
    portRS485: '',
    portRS485BaudRate: '',
    customerConsignmentNotification: '',
    customerCCNotification: '',
  }

  languages = [
    { id: 'es', name: 'Español (ES)' },
    { id: 'en', name: 'Ingles (EN)' },
    { id: 'fr', name: 'Frances (FR)' }
  ];
  bussinessTypes = [
    { id: 'cc', name: 'Click & Collect' },
    { id: 'consigna', name: 'Consigna' }
  ];

  multipleLanguages = true;
  closeOnSelect = false;

  specialParamsModel: any = {
    response: null,
    statusResponse: 0,
    locks: [],
    titleOperation: 'Habilitar',
    titleCold: 'Frío',
    selected: null,
    disabledOperation: true,
    lockers: [{
      id: '',
      businessType: ''
    }]
  }
  lockSize: any = {
    id: '',
    PCB: '',
    portInPCB: '',
    x: '',
    y: '',
    businessType: '',
    size: '',
    width: '',
    height: '',
    cold: '',
    idLock: '',
    isSecondColumn: false
  }
  lockSizes = [];

  userModel: any = {
    id: '',
    name: '',
    email: '',
    username: '',
    password: '',
    phoneNumber: '',
    notificationChannel: [],
    notificationChannelB: {
      sms: false,
      email: false
    },
    role: '',
    client: ''
  }
  users = [];
  userRole = {
    staffAll: 'CC y Consigna',
    staffCC: 'CC',
    staffConsigment: 'Consigna'
  };
  userOption = 1;
  userIndex = -1;

  specialParamChangeModel: any = {
    response: null,
    statusResponse: 0
  }

  size: string = '';
  modalRef: BsModalRef;
  clients: any[] = [{id: -1, name: "Seleccionar"}];
  clientSelected = '';
  permissions = [];
  plng = 0;
  configuration = '-1';

  modulesInfo = [];
  configSpecsEnabled = false;
  configSpecialParamsEnabled = false;
  configNetworkEnabled = false;
  configParamsEnabled = false;
  configUsersEnabled = false;
  configPhisycalParamsEnabled = false;

  selectedModuleInfo = null;
  showModuleInfoOptionsDiv = true;

  notificationText = null;

  secondColumnStartVar = [false, false, false, false, false, false, false, false];

  currentLockLength1 = 0;
  currentLockLength2 = 0;
  hasReachedMaximumLockLength1 = false;
  hasReachedMaximumLockLength2 = false;

  mainModule = "null";

  networkModel: any = {
    hostName: '',
    dhcpManagementType: 1,
    dhcpManagement: 1,
    fixedIp: '',
    vpnip: '',
    anydesk: '',
    gateway: '',
    mask: '',
    dns: '',
    portsmanagement: {
      tcpout1194: false,
      tcpout80: false,
      tcpout1883: false,
      tcpout53: false,
      tcpout443: false,
      tcpout3013: false
    }
  }

  constructor(private router: Router,
    private _platformService: PlatformService,
    private _jiraService: JiraService,
    private modalService: BsModalService,
    private loginService: LoginService) { }

  ngOnInit(): void {
    console.log('--home--');
    this._platformService.getClients().subscribe((res: any) => {
      this.clients = this.clients.concat(res);
    }, (err) => {
      console.log('Err', err);
    })

    this.clientSelected = localStorage.getItem('clientId');
    console.log({clientSelected: this.clientSelected});
    this.permissions = localStorage.getItem('permissions').split(',');
    this.plng = this.permissions.length;
    console.log(this.permissions);
    console.log(this.permissions.length);
    this.getAllInanctiveClients(1, 100);
  }

  getAllInanctiveClients(page, resultsPerPage) {
    this._jiraService.getAllInactiveLockers(page, resultsPerPage).subscribe((res: any) => {
      console.log({res})
      this.lockerIds = this.lockerIds.concat(res);
      this.lockerIdsClients = this.lockerIds.filter((lockId: any) => {
        console.log(lockId)
        console.log(this.clientSelected)
        return lockId.id == -1 || (lockId.client.id == parseInt(this.clientSelected))
      })
    }, (err) => {
      this.lockerIds = [ { id: -1, lockerId: 'Seleccionar' } ];
    });
  }

  changeClient() {

  }

  changeConfig() {
    if (this.lockerId == 'Seleccionar') {
      alert('Debe seleccionar un lockerId');
      return;
    }
    this.configSpecsEnabled = (this.configuration == '1');
    this.configNetworkEnabled = (this.configuration == '3');
    this.configParamsEnabled = (this.configuration == '4');
    this.configUsersEnabled = (this.configuration == '5');
    this.configPhisycalParamsEnabled = (this.configuration == '2');
    if (this.configuration != '-1') {
      this.getJSON();
    }
  }

  setConfig(config) {
    this.configuration = config;
    this.changeConfig();
  }

  getJSON() {
    return new Promise((resolve, reject) => {
      let nameFile = 'specs';
      console.log({type: typeof this.configuration, configuration: this.configuration})
      switch (this.configuration) {
        case '3': nameFile = 'network'; break;
        case '4': nameFile = 'params'; break
        case '2': nameFile = 'special-params'; break;
        case '5': nameFile = 'users'; break;
      }
      this.cleanSpecConfig();
      this.cleanParamsConfig();
      this.clearNetwork();
      this.clearUsers();
      this._platformService.getJSON(this.lockerId, nameFile).subscribe((res: any) => {
        console.log({...res, success: true})
        this.fillConfig(res);
        resolve(res);
      }, (err) => {
        console.log({...err, success: false})
        resolve({});
      });
    })

  }

  validateFields(model: any) {
    if (this.lockerId == 'Seleccionar') {
      alert('Debe setear un lockerId');
      return false;
    }
    return true;
  }

  async setSpecConfig() {

    const validateRes = this.validateFields(this.specModel);
    if(!validateRes) return;

    let flagValid = false;
    const data = {
      lockerId: this.lockerId
    };

    for (const key of Object.keys(this.specModel)) {
      let value = this.specModel[key];
      if(value != '') {
        if (['showScreenSaverQR', 'showScreenSaverLogo', 'showCustomerSection', 'webhookEnable'].indexOf(key) !== -1) {
          if (value === 'Si') {
            value = true;
          } else if (value === 'No') {
            value = false;
          }
        }
        flagValid = true;
        if (key === 'screenSaverElement' && value != '') {
          if (value == 'desactivado') {
            data['screenSaverShow'] = false;
            continue;
          } else {
            data['screenSaverShow'] = true;
          }
        }
        data[key] = value
      }
    }
    if(!flagValid) {
      alert('Debe rellenar al menos un campo');
      return;
    }

    var indexSelected = data['languages'].indexOf(data['languageSelected']);
    if (data['languageSelected'] !== "") {
      data['languages'].sort(function(x,y){ return x == data['languageSelected'] ? -1 : y == data['languageSelected'] ? 1 : 0; });
    }

    const jsonData = {
      clientId: this.clientSelected,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true
      }],
      specs: {
        ...data
      }
    }

    console.log({data, jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })

  }

  cleanSpecConfig() {
    this.specModel = {
      lockerLocation: '',
      timeOutSS: '',
      locksPerBoard: '',
      boardsNumber: '',
      descriptionLocker: '',
      screenSaverShow: '',
      screenSaverElement: '',
      showScreenSaverQR: '',
      showScreenSaverLogo: '',
      showCustomerSection: '',
      supportContactPhone: '',
      webhookEnable: '',
      webhookURL: '',
      languages: [],
      languageSelected: '',
      bussinessTypes: []
    };
  }

  cleanParamsConfig() {
    this.paramsModel = {
      lateOpenDelay: 5,
      expiredDelayCC: 168,
      expiredDelayCustomer: 24,
      waitForCCNotification: 5,
      notificationTimeOutPreExpiredOrderCCMinutes: '',
      notificationTimeOutPreExpiredOrderConsignaMinutes: '',
      expiredDelayStaff: 0,
      statusLocker: '',
      outOfService: '',
      onlyPickupMode: '',
      hasPayment: '',
      consignaCostPerMinute: 0,
      lyraEmail: '',
      companyName: '',
      supportCompanyName: '',
      supportCompanyNameES: '',
      supportCompanyNameEN: '',
      supportCompanyNameFR: '',
      portRS485: '',
      customerConsignmentNotification: '',
      customerCCNotification: '',
    }
    // this.languages = [{
    //   id: '', name: 'Seleccionar'
    // }];
  }

  async setParamsConfig() {

    const validateRes = this.validateFields(this.paramsModel);
    if(!validateRes) return;

    let flagValid = false;
    const data = {};

    for (const key of Object.keys(this.paramsModel)) {
      let value = this.paramsModel[key];
      if(value != '') {
        if (['outOfService', 'onlyPickupMode', 'hasPayment', 'customerConsignmentNotification', 'customerCCNotification'].indexOf(key) !== -1) {
          if (value === 'Si') {
            value = true;
          } else if (value === 'No') {
            value = false;
          }
        }
        flagValid = true;
        data[key] = value
      }
    }
    if(!flagValid) {
      alert('Debe rellenar al menos un campo');
      return;
    }

    data['outOfService'] = false;
    data['onlyPickupMode'] = false;

    switch (data['statusLocker']) {
      case 'Fuera de servicio': data['outOfService'] = true; break;
      case 'Solo recogida': data['onlyPickupMode'] = true; break;
    }

    console.log('data', data);

    const jsonData = {
      clientId: this.clientSelected,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true
      }],
      params: {
        ...data
      }
    }

    console.log({data, jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  saveSize() {
    this.lockSizes.push(this.lockSize);
    this.lockSize = {
      id: '',
      PCB: '',
      portInPCB: '',
      x: '',
      y: '',
      businessType: '',
      width: '',
      height: '',
      cold: '',
      idLock: '',
      size: '',
      isSecondColumn: false
    };
    this.modalRef.hide();
  }

  saveLocksSizes() {

    const validateRes = this.validateFields(this.specialParamsModel);
    if(!validateRes) return;

    const jsonData = {
      clientId: this.clientSelected,
      name: `special-params-config-${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `special-params-config-${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true,
        locks: this.lockSizes
      }]
    }

    console.log({jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  clearSizes() {
    this.lockSizes = [];
    this.lockSize = {
      id: '',
      PCB: '',
      portInPCB: '',
      x: '',
      y: '',
      businessType: '',
      width: '',
      height: '',
      cold: '',
      idLock: '',
      isSecondColumn: false
    };
  }

  saveUser() {
    this.userModel.client = this.clientSelected;

    console.log({userModel: this.userModel});

    this.userModel.notificationChannelStr = this.userModel.notificationChannel.join(', ');
    this.userModel.roleStr = (this.userModel.role != '') ? this.userRole[this.userModel.role] : '';

    if(this.userOption == 1) {
      this.users.push(this.userModel);
    } else {
      this.users[this.userIndex] = this.userModel;
    }

    this.userModel = {
      email: '',
      username: '',
      password: '',
      phoneNumber: '',
      notificationChannel: [],
      notificationChannelB: {
        sms: false,
        email: false
      },
      role: '',
      client: ''
    };
    this.modalRef.hide();
  }

  async saveLockUser() {

    const validateRes = this.validateFields(this.specialParamsModel);
    if(!validateRes) return;

    const jsonData = {
      clientId: this.clientSelected,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true,
      }],
      users: this.users
    }

    console.log({jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  async saveLockSpecialParams(specialParams) {

    const validateRes = this.validateFields(this.specialParamsModel);
    if(!validateRes) return;

    const jsonData = {
      clientId: this.clientSelected,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true,
      }],
      specialParams: specialParams
    }

    console.log({jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  clearUsers() {
    this.users = [];
    this.userModel = {
      email: '',
      username: '',
      password: '',
      phoneNumber: '',
      notificationChannel: [],
      notificationChannelB: {
        sms: false,
        email: false
      },
      role: '',
      client: ''
    };
  }

  editUser(user: any, index) {
    this.userModel = user;
    this.userOption = 2;
    this.userIndex = index;
    this.showModal(this.addUserTemplate);
  }

  removeUser(user: any, index: any) {
    console.log({index, user, users: this.users});
    this.users.splice(index, 1)
    console.log({usersAfter: this.users});
  }

  async saveNetwork() {

    const validateRes = this.validateFields(this.networkModel);
    if(!validateRes) return;

    const jsonData = {
      clientId: this.clientSelected,
      name: `${this.lockerId}`,
      lockerId: this.lockerId,
      listConfigMedia: [{
        name: `${this.lockerId}`,
        urlFile: '',
        extensionName: 'json',
        isExternalFile: true
      }],
      network: {
        ...this.networkModel,
        hostname: this.networkModel.hostname,
        dhcpManagementType: (this.networkModel.dhcpmanagementtype == 1) ? 'Client Wired Network' : '4G Router',
        dhcpManagement: (this.networkModel.dhcpManagement == 1) ? 'DHCP' : 'Fixed Ip',
        fixedIp: this.networkModel.fixedIp
      }
    }

    console.log({jsonData});

    this.loading = true;
    this._platformService.createJSONs(jsonData).subscribe((res: any) => {
      alert('Configuracion seteada correctamente');
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

  clearNetwork() {
    this.networkModel = {
      hostName: '',
      dhcpManagementType: 1,
      dhcpManagement: 1,
      fixedIp: '',
      vpnip: '',
      anydesk: '',
      gateway: '',
      mask: '',
      dns: '',
      portsmanagement: {
        tcpout1194: false,
        tcpout80: false,
        tcpout1883: false,
        tcpout53: false,
        tcpout443: false,
        tcpout3013: false
      }
    }
  }

  showModal(template: TemplateRef<any>, userOption = 0) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    if(userOption != 0) {
      this.userOption = userOption;
    }
  }

  setLanguageSelected() {
    if (this.specModel.languages.length > 0) {
      this.specModel.languageSelected = this.specModel.languages[0];
    }
  }

  getPropertyOfLanguage(lang, property) {
    return lang[property]
  }

  fillConfig(data: any) {
    const config = data;
    if (config.success == false) return;
    if (this.configuration == '1') {
      this.specModel = {
        ...config,
        webhookEnable: (config.webhookEnable) ? 'Si' : 'No',
        showScreenSaverLogo: (config.showScreenSaverLogo) ? 'Si' : 'No',
        showCustomerSection: (config.showCustomerSection) ? 'Si' : 'No',
      };
      console.log({specModel: this.specModel});
    } else if (this.configuration == '4') {
      this.paramsModel = {
        ...config,
        hasPayment: (config.hasPayment) ? 'Si' : 'No'
      }
      this.setLanguageSelected();
    } else if (this.configuration == '3') {
      this.networkModel = {
        ...config,
        dhcpManagementType: (config.dhcpManagementType == 'Client Wired Network') ? 1 : 2,
        dhcpManagement: (config.dhcpManagement == 'DHCP') ? 1 : 2,
      }
    } else if (this.configuration == '5') {
      this.users = config;
    } else if (this.configuration == '2') {
      this.receiveSpecialParamsJSON([...config.lockers]);
    }
  }

  newLocker() {
    alert('En construcción');
  }

  changeNotification() {
    this.userModel.notificationChannel = [];
    if(this.userModel.notificationChannelB.sms) this.userModel.notificationChannel.push('sms');
    if(this.userModel.notificationChannelB.email) this.userModel.notificationChannel.push('email');
    console.log({notificationChannel: this.userModel.notificationChannel});
  }

  trackByFn(index, moduleNum) {
    return moduleNum.locksNumber;
  }

  addModuleInfo() {
    let numberOfColumns = 0;
    for(let i = 0; i < this.modulesInfo.length; i++){
      if (this.modulesInfo[i].moduleType !== 'aux') {
        numberOfColumns += 1;
      }
    }
    if (numberOfColumns < 16){
      this.modulesInfo.sort(this.compareFnCharCode);
      let aux = 0;
      for(let i = 0; i < this.modulesInfo.length; i++){
        this.modulesInfo[i].id = String.fromCharCode(i + 65 + aux);
        /* if (this.modulesInfo[i].twoColumnModule) {
          aux += 1;
        } */
      }
      const newCharCode = this.modulesInfo.length > 0 ? String.fromCharCode(this.modulesInfo[this.modulesInfo.length - 1].id.charCodeAt(0) + 1)  : String.fromCharCode(65);
      this.modulesInfo.push({
        id: newCharCode,
        moduleType: -1,
        locksNumber: 100,
        locks: [],
        temperature: 'normal',
        isMain: false,
        hasMain: null,
        isReducedModule: false,
        twoColumnModule: false,
        mainColumnIfAux: null,
        secondColumnStart: null,
        businessType: null
      });
    } else {
      if ( this.notificationText !== "No puede haber más de 16 módulos") {
        setTimeout( () => {
          this.notificationText = null
        }, 8000);
      }
      this.notificationText = "No puede haber más de 16 módulos";
    }
  }

  compareFnCharCode(a, b) {
    if (a.id.charCodeAt(0) < b.id.charCodeAt(0) ) {
      return -1;
    }
    if (a.id.charCodeAt(0) > b.id.charCodeAt(0)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  deleteFromModulesInfo(idLetter) {
    const isSameId = (element) => element.id === idLetter;
    const index = this.modulesInfo.findIndex(isSameId);
    this.modulesInfo.sort(this.compareFnCharCode);
    if (this.modulesInfo[index].twoColumnModule) {
      this.modulesInfo.splice(index, 2);
    } else {
      this.modulesInfo.splice(index, 1);
    }
    this.modulesInfo.sort(this.compareFnCharCode);
    let aux = 0;
    for(let i = 0; i < this.modulesInfo.length; i++){
      // System.out.println(nombres[i] + " " + sueldos[i]);
      this.modulesInfo[i].id = String.fromCharCode(i + 65 + aux);
      /* if (this.modulesInfo[i].twoColumnModule) {
        aux += 1;
      } */
    }
  }

  editModuleInfoConfig(i) {
    this.selectedModuleInfo = JSON.parse(JSON.stringify(this.modulesInfo[i]));
    for(const [index, lock] of this.selectedModuleInfo.locks.entries()) {
      if (lock.size === 'drawer' || lock.size === 'bike' || lock.size === 'cart' || lock.size === 'scooter') {
        this.selectedModuleInfo.locks[index].size = 's';
      }
    }
    this.loadSelectedModule();
    this.showModuleInfoOptionsDiv = true;
    this.refreshsecondColumnStartChangedCheked();
  }

  changeLocksNumber(index, numberOfLocks){
    if (this.modulesInfo[index].moduleType === 'cs' || this.modulesInfo[index].moduleType === 'cs'){
      if (numberOfLocks === 7) {
        numberOfLocks = 8;
        this.modulesInfo[index].locksNumber = 8;
      } else if (numberOfLocks === 5) {
        numberOfLocks = 6;
        this.modulesInfo[index].locksNumber = 6;
      } else if (numberOfLocks === 3) {
        numberOfLocks = 4;
        this.modulesInfo[index].locksNumber = 4;
      } else if (numberOfLocks === 1) {
        numberOfLocks = 2;
        this.modulesInfo[index].locksNumber = 2;
      }
    }
    if (this.modulesInfo[index].moduleType === 'cc' && numberOfLocks > 4){
        numberOfLocks = 4;
        this.modulesInfo[index].locksNumber = 4;
    }

    let locksNew = parseInt(numberOfLocks, 10);
    if (locksNew !== 100) {
      for(let i = 0; i < locksNew; i++) {
        if (this.modulesInfo[index].locks.length <= (i)){
          this.modulesInfo[index].locks.push({
          id: uuid.v4(),
          PCB: '',
          portInPCB: '',
          x: '',
          y: '',
          businessType: '',
          size: '',
          width: '',
          height: '',
          cold: '',
          idLock: '',
          isSecondColumn: false
          })
        }
      }
      if ((this.modulesInfo[index].locks.length - (locksNew)) > 0){
        this.modulesInfo[index].locks.splice( locksNew, this.modulesInfo[index].locks.length - (locksNew));
      }
    } else {
      this.modulesInfo[index].locks.splice( 0, this.modulesInfo[index].locks.length);
    }

  }

  exitModuleInfoOptionsDiv() {
    this.selectedModuleInfo = null;
    this.showModuleInfoOptionsDiv = false;
  }

  changedBusinessType(id, i, $event) {
    let hasMain = null;
    for (const [index, value] of this.selectedModuleInfo.locks.entries()) {
      if (value.businessType === 'tech' || value.businessType === 'screen') {
        hasMain = index;
      }
      if ((value.businessType === 'tech' || value.businessType === 'screen') && index !== i) {
        this.selectedModuleInfo.locks[index].businessType = 'disabled';
        if ( this.notificationText !== `La antigua taquilla de tecnología (Taquilla ${index + 1}) ha sido deshabilitada`) {
          setTimeout( () => {
            this.notificationText = null
          }, 8000);
        }
        this.notificationText = `La antigua taquilla de tecnología (Taquilla ${index + 1}) ha sido deshabilitada`;
      }
    }
    if (hasMain) {
      this.selectedModuleInfo.hasMain = hasMain;
    } else {
      this.selectedModuleInfo.hasMain = hasMain;
    }
  }

  changedMainModule() {
    for (const [index, value] of this.modulesInfo.entries()) {
      if(value.id === this.mainModule) {
        this.modulesInfo[index].isMain = true;
      } else {
        if (value.isMain) {
          for (const [index2, value2] of value.locks.entries()) {
            if (value2.businessType === 'tech' || value2.businessType === 'screen') {
              this.modulesInfo[index].locks[index2].businessType = 'disabled';
              if ( this.notificationText !== `Hemos deshabilidado la antigua taquilla de tecnología del módulo ${value.id}`) {
                setTimeout( () => {
                  this.notificationText = null
                }, 8000);
              }
              this.notificationText = `Hemos deshabilidado la antigua taquilla de tecnología del módulo ${value.id}`;
              break;
            }
          }
        }
        this.modulesInfo[index].isMain = false;
        this.modulesInfo[index].hasMain = null;
      }
    }
  }

  exitAndSaveModuleInfoOptionsDiv() {
    let doContinue = true;

    for (let [ii, lock] of this.selectedModuleInfo.locks.entries()) {
      if ((lock.portInPCB && (!lock.size || !lock.businessType)) || (lock.size && !lock.businessType)) {
        doContinue = false;
        if ( this.notificationText !== `No ha configurado el modelo de negocio para la taquilla ${ii + 1}`) {
          setTimeout( () => {
            this.notificationText = null
          }, 8000);
        }
        this.notificationText = `No ha configurado el modelo de negocio para la taquilla ${ii + 1}`;
        break;
       }
    }
    if (doContinue) {
      for (const [index, value] of this.modulesInfo.entries()) {
        if(value.id === this.selectedModuleInfo.id) {
          this.modulesInfo[index] = this.selectedModuleInfo;
        }
      }
      this.selectedModuleInfo = null;
      this.showModuleInfoOptionsDiv = false;
      this.hasReachedMaximumLockLength1 = false;
      this.hasReachedMaximumLockLength2 = false;
      this.currentLockLength1 = 0;
      this.currentLockLength2 = 0;
    }
  }

  changedModuleInfoType(index, type, locksNumber) {
    if (((type === "cs" || type === "cb") && locksNumber%2 !== 0) || ((type === "cs" || type === "cb") && locksNumber === '8') || (type === 'cc' && locksNumber > 3 && locksNumber !== 100)) {
      if ((type === "cs" || type === "cb") && locksNumber%2 !== 0) {
        if (this.modulesInfo[index].locksNumber !== '7'){
        this.modulesInfo[index].locksNumber = (parseInt(this.modulesInfo[index].locksNumber) + 1).toString();
      } else {
        this.modulesInfo[index].locksNumber = '6';
      }
      }
      if ((type === "cs" || type === "cb") && locksNumber === '8') {
        this.modulesInfo[index].locksNumber = '6';
      }
      if (type === "cc" && locksNumber > 3) {
        this.modulesInfo[index].locksNumber = '3';
      }
      let locksNew = parseInt(this.modulesInfo[index].locksNumber, 10);
      if (locksNew !== 100) {
        for(let i = 0; i < locksNew; i++) {
          if (this.modulesInfo[index].locks.length <= (i)){
            this.modulesInfo[index].locks.push({
            id: uuid.v4(),
            PCB: '',
            portInPCB: '',
            x: '',
            y: '',
            businessType: '',
            size: '',
            width: '',
            height: '',
            cold: '',
            idLock: '',
            isSecondColumn: false
            })
          }
        }
        if ((this.modulesInfo[index].locks.length - (locksNew)) > 0){
          this.modulesInfo[index].locks.splice( locksNew, this.modulesInfo[index].locks.length - (locksNew));
        }
      } else {
        this.modulesInfo[index].locks.splice( 0, this.modulesInfo[index].locks.length);
      }
    }
    if (type !== 'cl') {
      this.modulesInfo[index].isReducedModule = false;
      this.modulesInfo[index].twoColumnModule = false;
      this.modulesInfo[index].temperature = 'normal';
    }
    if (type !== 'cl' && this.modulesInfo[index +1] && this.modulesInfo[index +1].moduleType === 'aux') {
      let idCharCode = this.modulesInfo[index].id.charCodeAt(0);
      for(let i = 0; i < this.modulesInfo.length; i++){
        if ( (i + 65) === (idCharCode + 1) && this.modulesInfo[i].moduleType === 'aux') {
          this.modulesInfo.splice(i, 1);
          if (this.modulesInfo[i]){
          this.modulesInfo[i].id = String.fromCharCode(i + 65);
        }
        }
        if ( (i + 65) > (idCharCode + 1)) {
          this.modulesInfo[i].id = String.fromCharCode(i + 65);
        }
      }
    }
  }

  changedTwoColumnModule(id, twoColumnBool) {
    const aux = this.modulesInfo.filter( x => x.moduleType === 'aux' && x.id === String.fromCharCode(id.charCodeAt(0) + 1));
    if (twoColumnBool && aux) {
      const idCharCode = id.charCodeAt(0);
      for(let i = 0; i < this.modulesInfo.length; i++){
        if ( (i + 65) >= (idCharCode + 1)) {
          this.modulesInfo[i].id = String.fromCharCode(i + 66);
        }
      }
      const newCharCode = String.fromCharCode(id.charCodeAt(0) + 1);
      this.modulesInfo.push({
        id: newCharCode,
        moduleType: 'aux',
        locksNumber: 100,
        locks: [],
        temperature: 'normal',
        isMain: false,
        hasMain: null,
        mainColumnIfAux: id,
        isReducedModule: false,
        twoColumnModule: false,
        businessType: null
      });
      this.modulesInfo.sort(this.compareFnCharCode);
    } else if (!twoColumnBool) {
      /* console.log(this.selectedModuleInfo.secondColumnStart, this.selectedModuleInfo.locks.length - this.selectedModuleInfo.secondColumnStart);
      console.log(21,  this.selectedModuleInfo.secondColumnStart ? this.selectedModuleInfo.locks.splice(0, this.selectedModuleInfo.secondColumnStart) : this.selectedModuleInfo.locks); */
      /* const auxModuleLocks = this.selectedModuleInfo.secondColumnStart ? ( : this.selectedModuleInfo.locks; */
      if (this.selectedModuleInfo.secondColumnStart) {
        //this.selectedModuleInfo.locks.splice(this.selectedModuleInfo.secondColumnStart, this.selectedModuleInfo.locks.length - this.selectedModuleInfo.secondColumnStart);
        // this.selectedModuleInfo.locks.splice(0, this.selectedModuleInfo.secondColumnStart);
        for (let [index, lockk] of this.selectedModuleInfo.locks.entries()) {
          if (index >= this.selectedModuleInfo.secondColumnStart) {
            this.selectedModuleInfo.locks[index].size = '';
            this.selectedModuleInfo.locks[index].businessType = '';
          }
        }
        this.selectedModuleInfo.secondColumnStart = null;
      }
      const idCharCode = id.charCodeAt(0);
      for(let i = 0; i < this.modulesInfo.length; i++){
        if ( (i + 65) === (idCharCode + 1) && this.modulesInfo[i].moduleType === 'aux') {
          this.modulesInfo.splice(i, 1);
          if (this.modulesInfo[i]) {
          this.modulesInfo[i].id = String.fromCharCode(i + 65);
          }
        }
        if ( (i + 65) > (idCharCode + 1)) {
          this.modulesInfo[i].id = String.fromCharCode(i + 65);
        }
      }
      this.modulesInfo.sort(this.compareFnCharCode);
      this.changedIsReduced();
    }

    this.checkLockLength();
  }

  compareFnLocks(a, b) {
    if (a.id.charCodeAt(0) < b.id.charCodeAt(0) ) {
      return -1;
    }
    if (a.id.charCodeAt(0) > b.id.charCodeAt(0)) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  changedSize (id, lockIndex, newSize) {
    /* const i = id.charCodeAt(0) - 65;
    let lockIndexForDiv = lockIndex;
    console.log(lockIndex, lockIndexForDiv);
    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= lockIndex) {
      lockIndexForDiv = lockIndex - this.selectedModuleInfo.secondColumnStart + 8;
    }

    console.log(lockIndex, lockIndexForDiv);
    switch (newSize) {
      case 's':
      if ( this.selectedModuleInfo.isReducedModule) {
        this.selectedModuleInfo.locks[lockIndex].height = "98px";
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "98px");
      } else {
        this.selectedModuleInfo.locks[lockIndex].height = "74px";
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "74px");
      }
      break;
      case 'm':
      if ( this.selectedModuleInfo.isReducedModule) {
        this.selectedModuleInfo.locks[lockIndex].height = "195px";
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "195px");
      } else {
        this.selectedModuleInfo.locks[lockIndex].height = "147px";
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "147px");
      }
      break;
      case 'l':
        if ( this.selectedModuleInfo.isReducedModule) {
          this.selectedModuleInfo.locks[lockIndex].height = "293px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "293px");
        } else {
          this.selectedModuleInfo.locks[lockIndex].height = "221px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "221px");
        }
      break;
      case 'xl':
        if ( this.selectedModuleInfo.isReducedModule) {
          this.selectedModuleInfo.locks[lockIndex].height = "392px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "392px");
        } else {
          this.selectedModuleInfo.locks[lockIndex].height = "295px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "295px");
        }
      break;
      case 'xxl':
      this.selectedModuleInfo.locks[lockIndex].height = "590px";
      document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "590px");
      break;
      default: this.selectedModuleInfo.locks[lockIndex].height = "";
    }
 */
    if ((this.selectedModuleInfo.locks[lockIndex].businessType === 'tech' || this.selectedModuleInfo.locks[lockIndex].businessType === 'screen') && newSize !== 'm') {
      this.selectedModuleInfo.locks[lockIndex].businessType = '';
    }
    this.changedIsReduced();

  }

  changedIsReduced() {
    this.checkLockLength();
    let index;
    for (let [lockIndex, value] of this.selectedModuleInfo.locks.entries()) {
      index = lockIndex;
      let lockIndexForDiv = lockIndex;
      if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= lockIndex) {
        lockIndexForDiv = lockIndex - this.selectedModuleInfo.secondColumnStart + 8;
      }
      index = lockIndexForDiv;
      switch (value.size) {
        case 's':
        if ( this.selectedModuleInfo.isReducedModule) {
          this.selectedModuleInfo.locks[lockIndex].height = "98px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "98px");
        } else {
          this.selectedModuleInfo.locks[lockIndex].height = "74px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "74px");
        }
        break;
        case 'm':
        if ( this.selectedModuleInfo.isReducedModule) {
          this.selectedModuleInfo.locks[lockIndex].height = "195px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "195px");
        } else {
          this.selectedModuleInfo.locks[lockIndex].height = "147px";
          document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "147px");
        }
        break;
        case 'l':
          if ( this.selectedModuleInfo.isReducedModule) {
            this.selectedModuleInfo.locks[lockIndex].height = "293px";
            document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "293px");
          } else {
            this.selectedModuleInfo.locks[lockIndex].height = "221px";
            document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "221px");
          }
        break;
        case 'xl':
          if ( this.selectedModuleInfo.isReducedModule) {
            this.selectedModuleInfo.locks[lockIndex].height = "392px";
            document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "392px");
          } else {
            this.selectedModuleInfo.locks[lockIndex].height = "295px";
            document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "295px");
          }
        break;
        case 'xxl':
        this.selectedModuleInfo.locks[lockIndex].height = "590px";
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (lockIndexForDiv + 1), "590px");
        break;
        default: this.selectedModuleInfo.locks[lockIndex].height = "";
      }
    }

    for (let i = index + 2; i <= 16; i++) {
      document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (i), "0px");
    }
    this.checkLockLength();
  }

  changedModuleTemperature (income ) {
    if (income === 'negativeCold2' || income === 'cold2' ) {
      this.selectedModuleInfo.twoColumnModule = true;
      this.selectedModuleInfo.isReducedModule = true;
      this.changedIsReduced();
      this.changedTwoColumnModule(this.selectedModuleInfo.id, true);
    } else {
      this.selectedModuleInfo.twoColumnModule = false;
      this.changedTwoColumnModule(this.selectedModuleInfo.id, false);
    }
  }

  loadSelectedModule() {
    for (let i = 0; i < 8; i++) {
      if (this.selectedModuleInfo.locks[i]?.height) {
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (i + 1), this.selectedModuleInfo.locks[i].height);
      } else {
        document.querySelector<HTMLElement>(':root').style.setProperty('--lock' + (i + 1), "0px");
      }
    }
   /*  if (this.selectedModuleInfo.isReducedModule) {
      document.querySelector<HTMLElement>(':root').style.setProperty('--isReducedMultiplicator', "1.33");
    } else {
      document.querySelector<HTMLElement>(':root').style.setProperty('--isReducedMultiplicator', "1");
    } */
  }

  checkLockLength () {
    let newTotal1 = 0;
    let breaked1 = false;
    let newTotal2 = 0;
    let breaked2 = false;
    for (const [lockIndex, value] of this.selectedModuleInfo.locks.entries()) {
      if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= lockIndex) {
        newTotal2 += parseInt(value.height.substring(0, value.height.length - 2), 10);
        if (newTotal2 > 600) {
          this.selectedModuleInfo.locks[lockIndex] = {
            id: uuid.v4(),
            PCB: '',
            portInPCB: '',
            x: '',
            y: '',
            businessType: '',
            size: '',
            width: '',
            height: '',
            cold: '',
            idLock: '',
            isSecondColumn: false
          }
          breaked2 = true;
        }
      } else {
        newTotal1 += parseInt(value.height.substring(0, value.height.length - 2), 10);
        if (newTotal1 > 600) {
          this.selectedModuleInfo.locks[lockIndex] = {
            id: uuid.v4(),
            PCB: '',
            portInPCB: '',
            x: '',
            y: '',
            businessType: '',
            size: '',
            width: '',
            height: '',
            cold: '',
            idLock: '',
            isSecondColumn: false
          }
          breaked1 = true;
          }
        }
        if (this.selectedModuleInfo.secondColumnStart) {
          lockIndex < this.selectedModuleInfo.secondColumnStart ? this.currentLockLength1 = newTotal1 : this.currentLockLength2 = newTotal2;
        } else {
          this.currentLockLength1 = newTotal1;
        }
    }
    if (breaked1) {
      if ( this.notificationText !== `Ha superado el tamaño máximo del módulo ${this.selectedModuleInfo.id.toUpperCase()}`) {
        setTimeout( () => {
          this.notificationText = null
        }, 8000);
      }
      this.notificationText = `Ha superado el tamaño máximo del módulo ${this.selectedModuleInfo.id.toUpperCase()}`;
      this.changedIsReduced();
    }
    if (breaked2) {
      if ( this.notificationText !== `Ha superado el tamaño máximo del módulo ${String.fromCharCode((this.selectedModuleInfo.id).charCodeAt(0) + 1).toUpperCase()}`) {
        setTimeout( () => {
          this.notificationText = null
        }, 8000);
      }
      this.notificationText = `Ha superado el tamaño máximo del módulo ${String.fromCharCode((this.selectedModuleInfo.id).charCodeAt(0) + 1).toUpperCase()}`;
      this.changedIsReduced();
    }
    this.hasReachedMaximumLockLength1 = this.currentLockLength1 > 550;
    this.hasReachedMaximumLockLength1 = this.currentLockLength2 > 550;
  }


  @ViewChild('imgDemoDiv') screenShot: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  refreshImg () {
       html2canvas(this.screenShot.nativeElement, {}).then(canvas => {
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'img-lockers.png';
        this.downloadLink.nativeElement.click();
      });
  }

  secondColumnStartChanged (index, checked) {

    if (checked.currentTarget.checked) {
      this.selectedModuleInfo.secondColumnStart = index;
    } else {
      if (this.selectedModuleInfo.locks.length > index) {
        this.selectedModuleInfo.secondColumnStart = index + 1;
        this.changedIsReduced();
      } else {
        this.selectedModuleInfo.secondColumnStart = null;
        this.changedIsReduced();
      }
    }

    for (const [index, value] of this.selectedModuleInfo.locks.entries()) {
      if (index >= this.selectedModuleInfo.secondColumnStart && (value.businessType === 'tech' || value.businessType === 'screen')) {
        this.selectedModuleInfo.locks[index].businessType = '';
      }
    }
   this.refreshsecondColumnStartChangedCheked();
   this.checkLockLength();
  }

  refreshsecondColumnStartChangedCheked () {
    for (let i = 0; i < 8; i++ ) {
      if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= i ) {
        this.secondColumnStartVar[i] = true;
      } else {
        this.secondColumnStartVar[i] = false;
      }
    }

    this.changedIsReduced()
    /* if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 1 ) {
      this.secondColumnStartVar[1] = true;
    } else {
      this.secondColumnStartVar[1] = false;
    }

    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 2 ) {
      this.secondColumnStartVar[2] = true;
    } else {
      this.secondColumnStartVar[2] = false;
    }

    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 3 ) {
      this.secondColumnStartVar[3] = true;
    } else {
      this.secondColumnStartVar[3] = false;
    }

    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 4 ) {
      this.secondColumnStartVar[4] = true;
    } else {
      this.secondColumnStartVar[4] = false;
    }

    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 5 ) {
      this.secondColumnStartVar[5] = true;
    } else {
      this.secondColumnStartVar[5] = false;
    }

    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 6 ) {
      this.secondColumnStartVar[6] = true;
    } else {
      this.secondColumnStartVar[6] = false;
    }

    if (this.selectedModuleInfo.secondColumnStart && this.selectedModuleInfo.secondColumnStart <= 7 ) {
      this.secondColumnStartVar[7] = true;
    } else {
      this.secondColumnStartVar[7] = false;
    } */
  }

  createSpecialParamsJSON () {
    let moduleWithTrouble = null;
    let noMainModule = false;
    let mainTechNoSelected = null;
    let mainModuleIsCl = false;
    let clColumnHasTech = false;
    const locksArray = [];
    let auxIndex = 0;
    let auxLockId = 1;
    let auxX = 0;
    let auxY = 'A';
    let auxPortInPCB = 0;
    if (this.mainModule === '-' || !this.mainModule) {
      noMainModule = true;
    }
    for (const modulex of this.modulesInfo ) {
      if (modulex.moduleType !== 'aux' && (((modulex.moduleType !== 'cl' && !modulex.businessType ) || modulex.locks.length === 0) || (modulex.moduleType === 'cl' && (  !modulex.locks[0] || ( modulex.locks[0] && (!modulex.locks[0].businessType || !modulex.locks[0].size)))))) {
        moduleWithTrouble = modulex.id;
        break;
      }
      if (!modulex.mainColumnIfAux) {
        auxPortInPCB = 0;
      }
      let auxModuleTemp = 'N1';
      switch (modulex.temperature) {
        case 'normal':
          auxModuleTemp = 'N1';
          break;
        case 'cold':
          auxModuleTemp = 'C1';
          break;
        case 'cold2':
          auxModuleTemp = 'C2';
          break;
        case 'negativeCold':
          auxModuleTemp = '-1';
          break;
        case 'negativeCold2':
          auxModuleTemp = '-2';
          break;
        case 'heat':
          auxModuleTemp = 'H1';
          break;
      }
      for (const [indexLock, lock] of modulex.locks.entries()) {
        let auxModuleSize = null
        switch (modulex.moduleType) {
          case 'cd':
            auxModuleSize = 'drawer';
            break;
          case 'cs':
            auxModuleSize = 'scooter';
            break;
          case 'cc':
            auxModuleSize = 'cart';
            break;
          case 'cb':
            auxModuleSize = 'bike';
            break;
          default:
            if (lock.size === 'drawer' && lock.size === 'bike' && lock.size === 'cart' && lock.size === 'scooter') {
              auxModuleSize = 's';
            } else {
              auxModuleSize = lock.size;
            }
            if (modulex.id === this.mainModule) {
              mainModuleIsCl = true;
              if (lock.businessType === 'tech' || lock.businessType === 'screen') {
                clColumnHasTech = true;
              }
            }
            break;
        }
        if (modulex.id !== auxY || modulex.secondColumnStart === indexLock) {
          auxY = modulex.id;
          auxX = 1;
        } else {
          auxX += 1;
        }
        if (auxModuleSize && (lock.businessType || (modulex.moduleType !== 'cl' && modulex.businessType))) {
          // console.log(modulex.mainColumnIfAux, modulex.secondColumnStart, indexLock, modulex.secondColumnStart >= indexLock);
          locksArray.push({
          id: auxLockId.toString(),
          PCB: modulex.mainColumnIfAux ? auxIndex - 1 : auxIndex,
          portInPCB: auxPortInPCB,
          x: auxX.toString(),
          y: modulex.secondColumnStart && modulex.secondColumnStart <= indexLock ? String.fromCharCode((modulex.id).charCodeAt(0) + 1) : modulex.id,
          businessType: modulex.moduleType === 'cl' && lock.businessType ? lock.businessType : modulex.businessType,
          size: auxModuleSize,
          width: `${auxModuleTemp}${modulex.isReducedModule ? '3' : '4'}${this.mainModule === modulex.id ? modulex.id : ''}`,
          height: lock.height,
          cold: !(modulex.temperature === 'normal'),
          idLock: lock.id.toString(),
          isSecondColumn: modulex.secondColumnStart && modulex.secondColumnStart <= indexLock
          });
          auxLockId += 1;
        }
        auxPortInPCB += 1;
      }
      if (modulex.moduleType !== 'aux') {
        auxIndex += 1;
      }

    }
    const toSend = {lockers: locksArray};
    if (mainModuleIsCl && !clColumnHasTech) {
      mainTechNoSelected = this.mainModule;
    }
     /* this.saveLockSpecialParams(toSend); */
     if (moduleWithTrouble) {
      if ( this.notificationText !== `No ha configurado correctamente el módulo ${moduleWithTrouble}`) {
        setTimeout( () => {
          this.notificationText = null
        }, 8000);
      }
      this.notificationText = `No ha configurado correctamente el módulo ${moduleWithTrouble}`;
     } else if (noMainModule) {
      if ( this.notificationText !== `No ha selecctionado un módulo de tecnología`) {
        setTimeout( () => {
          this.notificationText = null
        }, 8000);
      }
      this.notificationText = `No ha selecctionado un módulo de tecnología`;
     } else if (mainTechNoSelected) {
      if ( this.notificationText !== `No ha selecctionado una taquilla de tecnología en el módulo ${mainTechNoSelected}`) {
        setTimeout( () => {
          this.notificationText = null
        }, 8000);
      }
      this.notificationText = `No ha selecctionado una taquilla de tecnología en el módulo ${mainTechNoSelected}`;
     } else {
      this.saveLockSpecialParams(toSend);
     }
  }

  receiveSpecialParamsJSON (dto) {
    const auxModulesInfo = [];
    let auxLocks = [];
    let auxCurrentLetter = null;
    let startingPortInPCB
    let auxIsMain = false;
    let secondSave = false;
    let isReducedModuleAux = false;
    let isReducedModuleAuxLast = false;
    let isReducedModuleAux2 = false;
    let auxModuleTemp = 'normal';
    let auxModuleTempLast = 'normal';
    let lastBusinessType;
    for (const [index, lock] of dto.entries()) {
      let secondColumnStartAux = null;
      let createAuxModule = false;
      let auxModuleTemp = 'normal';
      switch (lock.width.substring(0,2)) {
        case 'N1':
          auxModuleTemp = 'normal';
          break;
        case 'C1':
          auxModuleTemp = 'cold';
          break;
        case 'C2':
          auxModuleTemp = 'cold2';
          if (auxLocks[0].size !== 'drawer' && auxLocks[0].size !== 'bike' && auxLocks[0].size !== 'cart' && auxLocks[0].size !== 'scooter'){
          createAuxModule = true;
        }
          break;
        case '-1':
          auxModuleTemp = 'negativeCold';
          break;
        case '-2':
          auxModuleTemp = 'negativeCold2';
          if (auxLocks[0].size !== 'drawer' && auxLocks[0].size !== 'bike' && auxLocks[0].size !== 'cart' && auxLocks[0].size !== 'scooter'){
            createAuxModule = true;
          }
          break;
        case 'H1':
          auxModuleTemp = 'heat';
          break;
      }
      /* console.log(lock.width.substring(0,2), lock.width.substring(2, 3), lock.width.substring(3)); */
      if (lock.width && lock.width.length >= 3) {

        if (lock.width && lock.width.length >= 4) {
          this.mainModule = lock.width.substring(3);
        }
        isReducedModuleAux = lock.width.substring(2, 3) === '3' ? true : false;
      }
      if ( index + 1  === dto.length) {
        secondSave = true;
      }
      if (auxCurrentLetter !== lock.y && lock.portInPCB !== 0) {
        startingPortInPCB = lock.portInPCB;
      }
      if ((auxCurrentLetter && auxCurrentLetter !== lock.y) && lock.portInPCB === 0 ) {
        /* if( index + 1 === dto.length && lock.portInPCB !== 0) {
          if ( (auxCurrentLetter && auxCurrentLetter !== lock.y) lock.startingPortInPCB !== 0 ) {

          } else {
          secondSave = true;
        }
      } */

      /* if ((lock.portInPCB !== 0) ) {
        auxLocks.push[lock];
      } else if ( index + 1 === dto.length &&  ) {
        secondSave = true;
      } */

      if ( index + 1 === dto.length && lock.portInPCB === 0  ) {
        secondSave = true;
      }


        const auxFirstLock = [auxLocks[0].size, auxLocks[0].cold];
        let auxModuleType = null;
        let auxMainModule = null;
        switch (auxFirstLock[0]) {
          case 'drawer':
            auxModuleType = 'cd';
            auxMainModule = null;
            break;
          case 'scooter':
            auxModuleType = 'cs';
            auxMainModule = null;
            break;
          case 'cart':
            auxModuleType = 'cc';
            auxMainModule = null;
            break;
          case 'bike':
            auxModuleType = 'cb';
            auxMainModule = null;
            break;
          default:
            auxModuleType = 'cl';
            auxMainModule = null;
            if (startingPortInPCB !== 0 && startingPortInPCB !== null){
              let hasChanged = false;
              for (const x of auxLocks) {
                if (!hasChanged && (String.fromCharCode(auxCurrentLetter.charCodeAt(0) + 1) === x.y)) {
                  hasChanged = true;
                  secondColumnStartAux = x.portInPCB;
                }
              }
              createAuxModule = true;
            }
            break;
        }
        const locksNum = auxLocks.length;
        auxModulesInfo.push({
          id: auxCurrentLetter,
          moduleType: auxModuleType,
          locksNumber: locksNum,
          locks: auxLocks,
          temperature: auxModuleTempLast,
          isMain: auxIsMain,
          hasMain: null,
          isReducedModule: isReducedModuleAuxLast,
          twoColumnModule: false,
          mainColumnIfAux: auxMainModule,
          secondColumnStart: secondColumnStartAux,
          businessType: lastBusinessType
        });
        isReducedModuleAuxLast = isReducedModuleAux;
        auxModuleTempLast = auxModuleTemp;
        lastBusinessType = lock.businessType;
        if ( createAuxModule ) {
          auxModuleType = 'aux';
          auxMainModule = String.fromCharCode(lock.y.charCodeAt(0) - 1);
          auxModulesInfo[auxModulesInfo.length - 1].twoColumnModule = true;
          auxModulesInfo.push({
            id: String.fromCharCode(auxCurrentLetter.charCodeAt(0) + 1),
            moduleType: 'aux',
            locksNumber: 100,
            locks: [],
            temperature: 'normal',
            isMain: false,
            hasMain: null,
            mainColumnIfAux: auxCurrentLetter,
            isReducedModule: false,
            twoColumnModule: false,
            businessType: null
          });
          createAuxModule = false;
        }
        auxIsMain = false;
        auxLocks = null;
        secondColumnStartAux = null;
        auxLocks = [];

      }

      if (lock.businessType === 'tech' || lock.businessType === 'screen') {
        auxIsMain = true;
      }
      auxLocks.push(lock);
      if (lock.portInPCB === 0) {
        auxCurrentLetter = lock.y;
      }
      createAuxModule = false;
      if ( secondSave) {
        /* if( index + 1 === dto.length) {
          auxLocks.push(lock);
          console.log(444, lock);
        } */
        // auxLocks.push(lock);
        const auxFirstLock = [auxLocks[0].size, auxLocks[0].cold];
        let auxModuleType = null;
        let auxMainModule = null;

        if (lock.width && lock.width.length >= 3) {
          if (lock.width && lock.width.length >= 4) {
            this.mainModule = lock.width.substring(3);
          }
          isReducedModuleAux = lock.width.substring(2, 3) === '3' ? true : false;
        }

        switch (lock.width.substring(0,2)) {
          case 'N1':
            auxModuleTemp = 'normal';
            break;
          case 'C1':
            auxModuleTemp = 'cold';
            break;
          case 'C2':
            auxModuleTemp = 'cold2';
            if (auxLocks[0].size !== 'drawer' && auxLocks[0].size !== 'bike' && auxLocks[0].size !== 'cart' && auxLocks[0].size !== 'scooter'){
              createAuxModule = true;
            }
            break;
          case '-1':
            auxModuleTemp = 'negativeCold';
            break;
          case '-2':
            auxModuleTemp = 'negativeCold2';
            if (auxLocks[0].size !== 'drawer' && auxLocks[0].size !== 'bike' && auxLocks[0].size !== 'cart' && auxLocks[0].size !== 'scooter'){
              createAuxModule = true;
            }
            break;
          case 'H1':
            auxModuleTemp = 'heat';
            break;
        }

        switch (auxFirstLock[0]) {
          case 'drawer':
            auxModuleType = 'cd';
            auxMainModule = null;
            break;
          case 'scooter':
            auxModuleType = 'cs';
            auxMainModule = null;
            break;
          case 'cart':
            auxModuleType = 'cc';
            auxMainModule = null;
            break;
          case 'bike':
            auxModuleType = 'cb';
            auxMainModule = null;
            break;
          default:
            auxModuleType = 'cl';
            auxMainModule = null;
            if (startingPortInPCB !== 0 && startingPortInPCB !== null){
              let hasChanged = false;
              for (const x of auxLocks) {
                if (!hasChanged && (String.fromCharCode(auxCurrentLetter.charCodeAt(0) + 1) === x.y)) {
                  hasChanged = true;
                  secondColumnStartAux = x.portInPCB;
                }
              }
              createAuxModule = true;
            }
            break;
        }
        auxModulesInfo.push({
          id: auxCurrentLetter,
          moduleType: auxModuleType,
          locksNumber: auxLocks.length,
          locks: auxLocks,
          temperature: auxModuleTemp,
          isMain: auxIsMain,
          hasMain: null,
          isReducedModule: isReducedModuleAux2,
          twoColumnModule: false,
          mainColumnIfAux: auxMainModule,
          secondColumnStart: secondColumnStartAux,
          businessType: lock.businessType
        });
        secondColumnStartAux = null;
        if ( createAuxModule ) {
          auxModuleType = 'aux';
          auxMainModule = String.fromCharCode(lock.y.charCodeAt(0) - 1);
          auxModulesInfo[auxModulesInfo.length - 1].twoColumnModule = true;
          auxModulesInfo.push({
            id: String.fromCharCode(auxCurrentLetter.charCodeAt(0) + 1),
            moduleType: 'aux',
            locksNumber: 100,
            locks: [],
            temperature: 'normal',
            isMain: false,
            hasMain: null,
            mainColumnIfAux: auxCurrentLetter,
            isReducedModule: false,
            twoColumnModule: false,
            businessType: null
          });
          createAuxModule = false;
        }
        auxIsMain = false;
        auxLocks = null;
        auxLocks = [];
      }
      if (lock.portInPCB === 0 ) {
        auxCurrentLetter = lock.y;
        startingPortInPCB = null;
      }


    }
    this.modulesInfo = auxModulesInfo;
  }
}

