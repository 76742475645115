import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { StartComponent } from './pages/start/start.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './common/interceptors/auth.interceptor';
import { MessageStepComponent } from './components/message-step/message-step.component';
import { CommonModule } from '@angular/common';
import { HomeComponent} from './pages/home/home.component';

import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from './common/pipes/pipes.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    StartComponent,
    MessageStepComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    PipesModule,
    ModalModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }, BsModalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
