<section class="vh-100" style="background-color: #508bfc;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-10">
          <div class="card shadow-2-strong" style="border-radius: 1rem;">
            <div class="card-body p-5 text-center">
              <div class="row justify-content-md-center">
                <div class="col-md-6">
                  <img [src]="logoHMY" alt="">
                  <h3 class="mb-5 title">Configurador de Nuevos Equipos</h3>
                  <div class="form-outline mb-4">
                    <input type="email" id="typeEmailX-2" [(ngModel)]="email" placeholder="test@test.com" class="form-control" />
                    <label class="form-label" for="typeEmailX-2">Email</label>
                  </div>
                  <div class="form-outline mb-4">
                    <input type="password" id="typePasswordX-2" [(ngModel)]="password" placeholder="****" class="form-control" />
                    <label class="form-label" for="typePasswordX-2">Password</label>
                  </div>
                  <button class="btn btn-primary btn-block" [disabled]="loading" (click)="login()">Iniciar Sesion</button>
                </div>
              </div>
              <div class="row justify-content-md-center mt-5 text-start">
                <div class="col-md-11">
                  <p><b>Por favor, tenga en cuenta:</b></p>
                  <p>Esta es una herramienta interna de HMY y sólo usuarios autorizados y formados deberían tener acceso a ella y utilizarla.</p>
                  <p>Recuerda que los cambios que realice aquí afectarán a una solución física de Lockers, así que proceda con cuidado y asegúrese de que todo es correcto antes de guardar cualquier cambio.</p>
                  <p>Debido a lo crítico de este procedimiento, todos los usuarios y sus acciones serán registrados en una base de datos para garantizar la trazabilidad de todos los procesos, de modo que no compartas tus credenciales con nadie.</p>
                  <p>Si consideras que deberías tener acceso a esta herramienta, por favor contacta con el departamento de Retail Tech para recibir la formación obligatoria y necesaria para ser incluido dentro de la base de datos de usuarios acreditados.</p>
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spinner" [hidden]="!loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>  
    </div>
  </section>